<script>
import HeaderBack from '@/components/UI/HeaderBack.vue'
import Region from '@/components/components/Inputs/region'

export default {
	name: 'MonitoringForTir',
	components: {
		HeaderBack,
		Region,
	},
	data() {
		return {
			per_page: 5,
			form: {
				start_at: null,
				finish_at: null,
			},
		}
	},
	methods: {
		search() {
			console.log(`Monitoring For Tir`)
		},
	},
}
</script>

<template>
	<div class="wrap__content">
		<div class="container">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['MonitoringForTir']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<HeaderBack
								:title="'Нура-Сарысуская межобластная бассейновая инспекция рыбного хозяйства'"
								:subtitle="'Комитета рыбного хозяйства МСХ РК'"
							/>

							<div class="card__content--body">
								<div class="table__block--content">
									<div class="row">
										<div class="col-md-3">
											<region />
										</div>

										<div class="col-md-3">
											<div class="monitoring__line--date">
												<div class="monitoring__date--item w-full">
													<div class="monitoring__date--text">
														{{ $t('filters.after') }}
													</div>
													<div class="monitoring__date--input w-full">
														<input type="date" v-model="form.start_at" class="date__input w-full" />
													</div>
												</div>
											</div>
										</div>

										<div class="col-md-3">
											<div class="monitoring__line--date">
												<div class="monitoring__date--item w-full">
													<div class="monitoring__date--text">
														{{ $t('filters.before') }}
													</div>
													<div class="monitoring__date--input w-full">
														<input
															type="date"
															v-model="form.finish_at"
															class="date__input w-full"
														/>
													</div>
												</div>
											</div>
										</div>

										<div class="col-md-3">
											<div class="form__btns">
												<button class="input-btn form__line--btn" @click="search">
													{{ $t('button.find') }}
												</button>
											</div>
										</div>

										<div class="col-md-3 mt-[12px]">
											<button class="input-btn form__line--btn mt-3">
												{{ $t('button.download_excel') }}
												<img src="@/assets/img/icon-file-w.svg" />
											</button>
										</div>
									</div>
								</div>

								<div class="w-full flex flex-wrap gap-[10px] mt-[33px]">
									<button
										:class="{
											'!bg-[#157FEE] !text-[#FFFFFF]': true,
										}"
										class="border-none outline-none rounded-[10px] p-[15px] inline-flex justify-center items-center text-center font-second font-semibold text-[14px] duration-300 text-second bg-[#F7FBFF] hover:!bg-[#157FEE] hover:!text-[#FFFFFF]"
									>
										Карагандинская область
									</button>
									<button
										:class="{
											'!bg-[#157FEE] !text-[#FFFFFF]': false,
										}"
										class="border-none outline-none rounded-[10px] p-[15px] inline-flex justify-center items-center text-center font-second font-semibold text-[14px] duration-300 text-second bg-[#F7FBFF] hover:!bg-[#157FEE] hover:!text-[#FFFFFF]"
									>
										Улытауская область
									</button>
								</div>

								<div class="mt-[30px] font-second font-semibold text-[16px] text-second">
									{{ `Сведения о вылове рыбы на 01.01.2025 г.` }}
								</div>

								<div class="mt-[30px]">
									<div>
										<div
											class="bg-[#F7FBFF] rounded-[10px] mb-[10px] overflow-hidden flex items-stretch box-border"
										>
											<div
												class="w-[40px] max-w-[40px] box-border p-[11px] flex justify-start items-start font-second font-semibold text-[11px] leading-none text-[#82819F] uppercase box-border"
											>
												{{ '№' }}
											</div>
											<div
												class="w-[247px] max-w-[247px] p-[11px] flex justify-start items-start font-second font-semibold text-[11px] leading-none text-[#82819F] uppercase box-border"
											>
												{{ 'Наименование водоема' }}
											</div>
											<div
												class="w-[247px] max-w-[247px] p-[11px] flex justify-start items-start font-second font-semibold text-[11px] leading-none text-[#82819F] uppercase box-border"
											>
												{{ 'Наименование района местонахождения водоема и (или) участка' }}
											</div>
											<div
												class="w-[247px] max-w-[247px] p-[11px] flex justify-start items-start font-second font-semibold text-[11px] leading-none text-[#82819F] uppercase box-border"
											>
												{{
													'Код местонахождения по КАТО  (классификатор административно-территориальных объектов)'
												}}
											</div>
											<div
												class="p-[11px] flex justify-start items-start font-second font-semibold text-[11px] leading-none text-[#82819F] uppercase"
											>
												<div>
													<div class="py-[11px] border-b border-b-[#D3E7FB] box-border">
														{{ 'Промысловый лов (тонн)' }}
													</div>
													<div class="flex">
														<div class="w-[75.5px] max-w-[75.5px] py-[5px] box-border">
															<div class="pr-[20.5px] border-r border-r-[#D3E7FB]">
																{{ 'квота вылова' }}
															</div>
														</div>
														<div
															class="w-[106.5px] max-w-[106.5px] py-[5px] pl-[20.5px] box-border"
														>
															{{ 'фактически вылов лено' }}
														</div>
													</div>
												</div>
											</div>
										</div>

										<div class="mb-[30px] flex">
											<div
												class="w-[40px] max-w-[40px] p-[11px] font-second font-semibold text-[14px] text-[#0A091D] text-center box-border"
											>
												<div class="mb-[10px]">{{ '1' }}</div>
												<div class="writing-vertical-rl transform rotate-[180deg]">
													{{ 'ИП Волков В.А.' }}
												</div>
											</div>

											<div class="flex flex-col gap-[10px] overflow-hidden">
												<div
													class="bg-[#F7FBFF] rounded-[10px] overflow-hidden py-[15px] px-[11px] box-border"
												>
													<div
														class="flex border-b border-b-[#D3E7FB] pb-[10px] mb-[10px] font-second font-normal text-[14px] text-[#0A091D]"
													>
														<div class="w-[247px] max-w-[247px] py-[5px] grow box-border">
															{{ 'Самаркандское вдхр. (уч 2)' }}
														</div>
														<div class="w-[247px] max-w-[247px] py-[5px] grow box-border">
															{{ 'Бухар-Жырауский' }}
														</div>
														<div class="w-[247px] max-w-[247px] py-[5px] grow box-border">
															{{ '350000000' }}
														</div>
														<div
															class="w-[75.5px] max-w-[75.5px] pr-[19.5px] border-r border-r-[#D3E7FB] grow box-border"
														>
															{{ '35,200' }}
														</div>
														<div class="w-[106.5px] max-w-[106.5px] pl-[19.5px] grow box-border">
															{{ '17,178' }}
														</div>
													</div>

													<div class="overflow-hidden">
														<div
															class="cursor-pointer flex justify-center items-center gap-[10px] bg-white duration-300 font-second font-semibold text-[14px] text-main p-[13.5px] box-border rounded-[10px] duration-300 hover:!bg-[#D3E7FB]"
														>
															<span>{{ 'В том числе по видам рыб' }}</span>
															<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'block transform origin-center rotate-180'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M18.5 15.95l-6-6-6 6" stroke="#157FEE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
														</div>

														<div class="overflow-y-auto scrollbar-blue">
															<div class="pt-[20px] pb-[10px] flex items-stretch gap-[20px]">
																<div
																	class="p-[10px] rounded-[10px] bg-white flex-shrink-0 w-[203px] font-second font-semibold text-[11px] text-[#82819F] uppercase leading-none"
																>
																	<div class="text-center border-b border-b-[#D3E7FB]">
																		{{ 'линь' }}
																	</div>
																	<div class="flex">
																		<div class="pr-[20.5px] border-r border-r-[#D3E7FB]">
																			<div>{{ 'квота вылова' }}</div>
																			<div>{{ '-' }}</div>
																		</div>
																		<div class="pl-[20.5px]">
																			<div>{{ 'факти чески вылов лено' }}</div>
																			<div>{{ '-' }}</div>
																		</div>
																	</div>
																</div>
																<div
																	class="p-[10px] rounded-[10px] bg-white flex-shrink-0 w-[203px] font-second font-semibold text-[11px] text-[#82819F] uppercase leading-none"
																>
																	<div class="text-center border-b border-b-[#D3E7FB]">
																		{{ 'линь' }}
																	</div>
																	<div class="flex">
																		<div class="pr-[20.5px] border-r border-r-[#D3E7FB]">
																			<div>{{ 'квота вылова' }}</div>
																			<div>{{ '-' }}</div>
																		</div>
																		<div class="pl-[20.5px]">
																			<div>{{ 'факти чески вылов лено' }}</div>
																			<div>{{ '-' }}</div>
																		</div>
																	</div>
																</div>
																<div
																	class="p-[10px] rounded-[10px] bg-white flex-shrink-0 w-[203px] font-second font-semibold text-[11px] text-[#82819F] uppercase leading-none"
																>
																	<div class="text-center border-b border-b-[#D3E7FB]">
																		{{ 'линь' }}
																	</div>
																	<div class="flex">
																		<div class="pr-[20.5px] border-r border-r-[#D3E7FB]">
																			<div>{{ 'квота вылова' }}</div>
																			<div>{{ '-' }}</div>
																		</div>
																		<div class="pl-[20.5px]">
																			<div>{{ 'факти чески вылов лено' }}</div>
																			<div>{{ '-' }}</div>
																		</div>
																	</div>
																</div>
																<div
																	class="p-[10px] rounded-[10px] bg-white flex-shrink-0 w-[203px] font-second font-semibold text-[11px] text-[#82819F] uppercase leading-none"
																>
																	<div class="text-center border-b border-b-[#D3E7FB]">
																		{{ 'линь' }}
																	</div>
																	<div class="flex">
																		<div class="pr-[20.5px] border-r border-r-[#D3E7FB]">
																			<div>{{ 'квота вылова' }}</div>
																			<div>{{ '-' }}</div>
																		</div>
																		<div class="pl-[20.5px]">
																			<div>{{ 'факти чески вылов лено' }}</div>
																			<div>{{ '-' }}</div>
																		</div>
																	</div>
																</div>
																<div
																	class="p-[10px] rounded-[10px] bg-white flex-shrink-0 w-[203px] font-second font-semibold text-[11px] text-[#82819F] uppercase leading-none"
																>
																	<div class="text-center border-b border-b-[#D3E7FB]">
																		{{ 'линь' }}
																	</div>
																	<div class="flex">
																		<div class="pr-[20.5px] border-r border-r-[#D3E7FB]">
																			<div>{{ 'квота вылова' }}</div>
																			<div>{{ '-' }}</div>
																		</div>
																		<div class="pl-[20.5px]">
																			<div>{{ 'факти чески вылов лено' }}</div>
																			<div>{{ '-' }}</div>
																		</div>
																	</div>
																</div>
																<div
																	class="p-[10px] rounded-[10px] bg-white flex-shrink-0 w-[203px] font-second font-semibold text-[11px] text-[#82819F] uppercase leading-none"
																>
																	<div class="text-center border-b border-b-[#D3E7FB]">
																		{{ 'линь' }}
																	</div>
																	<div class="flex">
																		<div class="pr-[20.5px] border-r border-r-[#D3E7FB]">
																			<div>{{ 'квота вылова' }}</div>
																			<div>{{ '-' }}</div>
																		</div>
																		<div class="pl-[20.5px]">
																			<div>{{ 'факти чески вылов лено' }}</div>
																			<div>{{ '-' }}</div>
																		</div>
																	</div>
																</div>
																<div
																	class="p-[10px] rounded-[10px] bg-white flex-shrink-0 w-[203px] font-second font-semibold text-[11px] text-[#82819F] uppercase leading-none"
																>
																	<div class="text-center border-b border-b-[#D3E7FB]">
																		{{ 'линь' }}
																	</div>
																	<div class="flex">
																		<div class="pr-[20.5px] border-r border-r-[#D3E7FB]">
																			<div>{{ 'квота вылова' }}</div>
																			<div>{{ '-' }}</div>
																		</div>
																		<div class="pl-[20.5px]">
																			<div>{{ 'факти чески вылов лено' }}</div>
																			<div>{{ '-' }}</div>
																		</div>
																	</div>
																</div>
																<div
																	class="p-[10px] rounded-[10px] bg-white flex-shrink-0 w-[203px] font-second font-semibold text-[11px] text-[#82819F] uppercase leading-none"
																>
																	<div class="text-center border-b border-b-[#D3E7FB]">
																		{{ 'линь' }}
																	</div>
																	<div class="flex">
																		<div class="pr-[20.5px] border-r border-r-[#D3E7FB]">
																			<div>{{ 'квота вылова' }}</div>
																			<div>{{ '-' }}</div>
																		</div>
																		<div class="pl-[20.5px]">
																			<div>{{ 'факти чески вылов лено' }}</div>
																			<div>{{ '-' }}</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="flex items-center gap-x-[30px]">
									<div class="font-second font-normal text-[14px] text-second leading-none">
										<span>{{ 'Общее кол-во дел: ' }}</span>
										<span>{{ '345' }}</span>
									</div>
									<div
										class="font-second font-normal text-[14px] text-second leading-none inline-flex gap-[5px]"
									>
										<span>{{ 'Строк на странице: ' }}</span>
										<div class="relative">
											<span>{{ '10' }}</span>
											<div
												v-show="false"
												class="absolute left-1/2 transform -translate-x-1/2 bottom-[150%] px-3 py-2 border border-second bg-white text-center"
											>
												<div class="cursor-pointer p-2">{{ '5' }}</div>
												<div class="cursor-pointer p-2">{{ '10' }}</div>
												<div class="cursor-pointer p-2">{{ '15' }}</div>
												<div class="cursor-pointer p-2">{{ '20' }}</div>
											</div>
										</div>
									</div>
									<div
										class="font-second font-normal text-[14px] text-second leading-none flex items-center gap-[10px]"
									>
										<span>{{ '1-10 из 70' }}</span>
										<div>
											<button>
												<svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'block transform origin-center rotate-180'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.177 14.204a.531.531 0 00.75 0l4.428-4.427a.531.531 0 000-.75L6.927 4.598a.531.531 0 00-.75.751l4.051 4.052-4.051 4.052a.532.532 0 000 .75z" fill="#475F84"/></svg>
											</button>
											<button>
												<svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'block'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.177 14.204a.531.531 0 00.75 0l4.428-4.427a.531.531 0 000-.75L6.927 4.598a.531.531 0 00-.75.751l4.051 4.052-4.051 4.052a.532.532 0 000 .75z" fill="#475F84"/></svg>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped></style>
